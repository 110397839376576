.chats-nav {
  display: flex;
  padding: 0;
  margin: 0 -24px;
  margin-top: -24px;
  list-style: none;


  &__item {
    flex: 1;

  }
  
  &__button {
    border: none;
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    font-size: 19px;
    color: $greyMain;
    padding: 15px 0;
    border-bottom: 1.5px solid $greyMain;
    transition: all .3s ease;

    @include media('<568px') {
      font-size: 0;
      padding: 10px 0;
    }

    &:hover {
      color: #fff;
      border-color: #fff;
    }

    .anticon {
      margin-right: 10px;

      @include media('<568px') {
        margin-right: 0;
        font-size: 21px;
      }
    }

    &.active {
      border-color: $primaryActiveColor;
      color: $primaryActiveColor;
    }
  }
}