.faq {
  padding: 30px 0;
}
.faq-nav {
  display: flex;
  justify-content: center;
  padding: 20px 0;

  @include media("<768px") {
    flex-direction: column;
  }

  >* {
    +* {
      margin-left: 15px;
      @include media("<768px") {
        margin-left: 0;
        margin-top: 10px;
      }

    }
  }
}

.faq-nav-card {
  padding: 10px 15px;
  border-radius: 0;
}
.faq-item {
  +.faq-item {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid rgba(250, 250, 250, .3);
  }
  color: #fff;

  &__question {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
  }
  &__answer {
    font-size: 12px;
    font-style: italic;
    color: rgba(250, 250, 250, .75);
    margin-bottom: 0;
  }
}

.faq-list {
  padding-bottom: 50px;
}