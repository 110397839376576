.landing-pages {
  position: relative;
  background: $dark;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;

  .welcome {
    max-width: 455px;
    padding: 0 20px;
    font-family: $mainSansSerif, sans-serif;

    p {
      margin: 7px 0 30px;
      text-align: center;
      font-size: 16px;
      line-height: 19px;
      font-weight: normal;
    }

    .btn__scroll--down {
      display: none;
    }
  }

  .portfolio {

    .slider {
      margin: 0 auto;

      & .slick-arrow {
        height: 45px;
        width: 45px;
        top: 129px;
      }

      & .slick-dots {
        bottom: -15px;
      }

      &__wrap {
        padding: 0 20px;
      }

      &__portfolio-item {
        display: flex;
        flex-direction: column;
        max-width: 380px;
        width: 100%;
        margin: 39px auto;

        &__logo {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 180px;
          margin-bottom: 25px;
          background-color: $defaultWhite;
          border-radius: 30px 30px 0 0;
          box-shadow: 0 15px 40px $slideBoxShadow;

          &:after {
            position: absolute;
            bottom: -12px;
            height: 0;
            width: 0;
            content: '';
            border-top: 12px solid $defaultWhite;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
          }
        }

        &__content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          h3 {
            align-self: flex-start;
            text-align: left;
          }

          span {
            margin-top: 9px;
            font-family: $mainSansSerif;
            font-size: 14px;
            line-height: 17px;
            font-weight: normal;
          }

          button {
            margin: 31px 0;

            a {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .menu-modal {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    padding: 25px 20px;
    overflow: auto;
    background-color: $dark;
    width: 100%;
    height: 100%;
    z-index: 9999;
    @include large-mobile {
      justify-content: center;
      align-items: center;
    }

    &__menu {
      padding: 0;
      margin: 52px 0;
      list-style: none;

      .menu-item {
        margin-bottom: 12px;
      }

      .language-flag {
        height: 75px;
        padding: 4px;
      }

      .menu-item a {
        font-family: foco, sans-serif;
        text-decoration: none;
        font-size: 50px;
        line-height: 58px;
        font-weight: 800;
        color: $defaultWhite;
      }
    }

    .login-area {
      display: flex;
      flex-direction: column;
      width: 100%;
      @include large-mobile {
        max-width: 290px;
      }

      .btn {
        &__content-wrap {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            margin: 0 0 3px 8px;
          }
        }
      }

      .btn__hollow {
        width: 100%;
        margin-bottom: 10px;
        border: 1px solid $opaqueGray2;
        color: $defaultWhite;
      }

      .btn__default {
        width: 100%;
      }
    }
  }

  .menu-modal.opened {
    display: flex;
    padding-bottom: 200px;
  }

  .modal {
    display: none;
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 85px 20px 150px;
    background: $modalBackground;
    width: 100%;
    height: 100%;


    .reset-pass-form.modal-form {
      @include desktop {
        .btn__default {
          width: 48.5%;
        }
      }
    }

    .modal-form,
    .registration-complete {
      position: relative;
      display: none;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      padding: 20px 30px 25px;
      max-width: 375px;
      background-color: $defaultWhite;
      border-radius: 15px;

      .captcha-box {
        display: none;
      }

      h3 {
        margin-bottom: 30px;
      }

      &__input-cont {
        width: 100%;
        margin-bottom: 30px;
      }

      span ~ .modal-form__input-cont {
        margin-top: 15px;
      }

      input {
        margin: 0;
        box-shadow: none;
        border: 1px solid $opaqueGray3;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &:focus {
          padding: 13px 23px;
          border: 2px solid $borderBlue;
        }
      }

      span {
        color: $opaqueBlack2;
      }

      .btn {
        margin: 0 0 10px;
        width: 100%;
      }

      .checkbox-cont {
        display: block;
        position: relative;
        margin: 10px 0;
        cursor: pointer;
        font-size: 14px;
        line-height: 16px;
        color: $opaqueBlack2;
        padding-left: 40px;
        user-select: none;

        &:focus {
          outline: none;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover input ~ .checkmark {
          background-color: $opaqueGray2;
        }

        input:checked ~ .checkmark {
          background-color: $dotBlue;
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 10px;
          height: 20px;
          width: 20px;
          background-color: #fff;
          border: 1px solid $opaqueGray3;
          border-radius: 2px;

          @media (min-width: 382px) {
            top: -3px;
          }

          &:after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }

        input:checked ~ .checkmark:after {
          display: block;
        }
      }

      &__footer {
        margin-top: 20px;
      }

      .clickable-text {
        display: block;
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
        line-height: 16px;
        color: $dotBlue;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      @include desktop {
        min-width: 640px;

        .captcha-box {
          display: block;
          height: 66px;
          background-color: $opaqueGray3;
          border-radius: 15px;
          text-align: center;
        }

        &__input-cont {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          input,
          div {
            width: 48.5%;
            margin: 0 auto;
          }
        }
      }
    }

    .registration-complete {
      h3 {
        margin: 0;
      }

      &__img-cont {
        margin: 30px auto;
      }

      span {
        text-align: center;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        .registration-complete__username {
          color: $titleBlack;
        }
      }
    }

    .modal-form.opened,
    .registration-complete.opened {
      display: flex;
    }
  }

  .modal.opened {
    display: block;
  }
}

.language-dropdown {
  font-size: 12px;
  color: $white;
  margin: 0 30px 0 0;
  flex-shrink: 0;
  text-transform: uppercase;

  &__flag {
    width: 20px;
    height: 20px;
    margin: 0 12px 0 0;
    border-radius: 100%;
  }

  &__arrow {
    margin-left: 5px;
    width: 8px;
    height: 5px;
  }
}

.languages-overlay {
  .ant-menu {
    background-color: $darkBox;
    border-radius: 8px;
    margin-top: 10px;
  }

  .ant-menu-item {
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    height: auto;
    min-height: initial;
    text-align: center;
    text-transform: uppercase;
    margin: 0 !important;
    padding: 4px 0;

    &:hover {
      color: $primaryActiveColor;
    }
  }

  .ant-menu-vertical {
    border-right: none;
  }
}


.contact-section {
  background: linear-gradient(140deg, #F06C2F 18.73%, #FF7A00 100%);
  border-radius: 20px;
  padding: 40px;
  position: relative;
  margin-bottom: 50px;

  @include media('<1024px') {
    padding: 15px;
  }

  &__image {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 475px;
    @include media('<1024px') {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

  }
}

.contact-section-form {
  width: 480px;
  max-width: 100%;
  position: relative;
  z-index: 2;

  &__info {
    h2 {
      font-size: 32px;
      line-height: 1;
      margin: 0;
      color: #1D1D2A;
      font-weight: 900;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      line-height: 1.5;
      margin: 0;
      margin-bottom: 20px;
    }
  }

  textarea.ant-input {
    height: 225px;
  }

  .ant-input {
    margin: 0;
    height: 50px;
    border-radius: 0px;
    font-family: $titleFont;
    //color: #091D32;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    box-shadow: none;

    &::placeholder {
      //color: #091D32;
    }
  }

  .ant-form-item-explain {
    color: #fff;
    padding: 0;
    font-size: 12px;
    margin-top: 5px;
  }

  .btn {
    min-width: 150px;
    padding: 10px 20px;
    font-size: 16px;
    line-height: 30px;
  }
}
