.promo-archive {
  .archive-item {
    margin: 14px 0;
  }

  &__collapse {
    border-radius: 15px;
    border: 1px solid rgba(250, 250, 250, .15);
    padding: 5px;
    & > .ant-collapse-item {
      border-bottom: none;
    }
    
    .ant-collapse-header {
      color: #fff !important;
      border-color:  rgba(250, 250, 250, .15) !important;
    }
    .ant-collapse-content {
      color: #fff !important;
      border: none !important;
      background: transparent !important;
    }
  }

  &__modal{
    &__close {
      padding: 10px;
    }
  }
}