.user-profile-preview {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.user-profile-link {
  color: #fff;
  font-size: 12px;
  line-height: 1.4;
  font-weight: bold;
  word-break: break-all;

  &:hover {
    color: $primaryColor;
  }
  span {
    color: gray;
    font-size: 8px;
    font-style: italic;
    display: block;
    font-weight: normal;
  }
}

.user-mails {
  display: flex;
  margin-right: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  color: #fff;
  border-radius: 0;
  background: $darkInput;
  width: 35px;
  height: 35px;
  flex: none;
  transition: all .2s ease;

  &:hover {
    color: #fff;
    background: $darkButton;
  }

  .anticon {
    font-size: 19px;

  }

  &__amount {
    text-align: center;
    display: block;
    color: gray;
    font-size: 9px;
    font-style: italic;
    font-weight: normal;
    position: absolute;
    width: 25px;
    height: 25px;
    left: -10px;
    top: -10px;
    background: #000;
    border-radius: 50%;
    line-height: 25px;
  }
}