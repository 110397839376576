.footer {
  left: 0;
  bottom: 0;
  padding-bottom: 20px;
  color: #fff;
  font-size: 12px;
  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $darkBox;
    border-radius: 0px;
    padding: 15px 20px;

    @include media('<1024px') {
      flex-direction: column;
    }

  }

  &__menu {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: auto;


    @include media('<1024px') {
      flex-direction: column;
      margin: 15px 0;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    li {
      +li {
        margin-left: 25px;
        @include media('<1024px') {
          margin-left: 0;
          margin-top: 5px;
        }
      }
    }
    a {
      color: #AEAED2;
      font-size: 11px;
      &:hover {
        color: $primaryActiveColor;
      }
      &.active {
        color: $primaryActiveColor;

      }
    }
  }
}
.footer-logo-18 {
  margin-left: 50px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;

  @include media('<1024px') {
    margin: 0 auto;
  }

  @keyframes shine {
    0% {
      fill: #AEAED2;
    }
    100% {
      fill: $primaryActiveColor;
    }
  }
  path {
    animation: shine 1s infinite ease alternate both;
  }
}