.chat-msg {
  > * {
    margin: 0;
  }

  max-width: 70%;
  margin-left: 0;
  margin-right: auto;
  position: relative;



  &__title {
    padding-left: 20px;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 5px;
    display: inline-block;

  }
  &__text {
    border-radius: 24px;
    padding: 10px 20px;
    background-color: $greyMain;
    color: #fff;
    margin-bottom: 5px;
    position: relative;
    font-size: 14px;
    line-height: 1.6;
    word-break: break-all;

    p {
      margin: 0;
    }

    pre {
      font-family: inherit;
      white-space: pre-wrap;
      word-wrap: break-word;
      margin: 0;
    }
    
    &::before {
      content: '';
      position: absolute;
      left: 8px;
      top: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 20px 0 0;
      border-color: $greyMain transparent transparent transparent;
      transform: rotate(12deg);
      margin-top: -10px;
    }
  }
  &__date {
    font-size: 12px;
    margin-left: 24px;
    text-align: left;
    position: relative;
    padding-left: 15px;
    color: #fff;
    &::before {
      content: '';
      width: 11px;
      height: 11px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background: url("../../../../../assets/images/inner-pages/chat/time.svg") no-repeat center / 100% 100%;
    }
  }
  &__edit {
    position: absolute;
    right: 100%;
    border: none;
    background: none;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 18px;
    opacity: 0;
    visibility: hidden;
    filter: alpha(opacity=0);
    transition: all .3s ease;
    &:hover {
      color: $primaryMain;
    }
  }

  &--author {
    margin-left: auto;
    margin-right: 0;
    .chat-msg {
      &__date {
        margin-right: 24px;
        padding-right: 15px;
        padding-left: 0;
        text-align: right;

        &::before {
          left: auto;
          right: 0;
        }
      }
      &__text {
        background-color: $primaryMain;
        color: #fff;

        &::before {
          left: auto;
          right: 8px;
          transform: rotate(-12deg);
          border-width: 0 20px 20px 0;
          border-color: transparent $primaryMain transparent transparent;
        }
      }
    }

    &:hover {
      .chat-msg{
        &__edit {
          opacity: 1;
          visibility: visible;
          filter: alpha(opacity=100);
        }
      }
    }
  }
  &--selected {
    .chat-msg {
      &__text {
        background-color: $secondaryMain;
        &::before {
          border-color: transparent $secondaryMain transparent transparent;
        }
      }
      &__edit {
        opacity: 1;
        visibility: visible;
        filter: alpha(opacity=100);
      }
    }
  }
}