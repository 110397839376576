.date-range-picker {


  &.ant-picker {
    height: 45px;
    border-radius: 0px;
    display: flex;
    align-items: center;
  }
  .ant-picker-input > input {
    text-align: center;
  }
}