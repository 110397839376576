.select-banner {
  padding: 10px;
  margin: 2px 5px;
  height: 100%;
  cursor: pointer;
  display: inline-block;
  border: 1px solid rgba(250, 250, 250, .15);
  vertical-align: top;
  color: #fff;
  &__selected {
    border-color: $primaryColor;
  }

  &:hover {
    box-shadow: rgba(250, 250, 250, .4);
  }

  &__inner {
    overflow: hidden;

    * {
      width: 100%;
      height: 100%;
    }
  }
}

.select-banner-wrapper {
  width: fit-content;
  margin: auto;
  display: inline-block;
}

.item-preview-wrapper {
  position: absolute;
  font-size: 28px;
  background-color: $darkBox;
  width: 50px;
}
