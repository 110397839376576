.info-item {
  font-size: 20px;
  line-height: 23px;
  text-align: left;

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: rgba(250, 250, 250, .15);
  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }
}