@import "vars";
@import "mixins";
@import "../assets/styles/helpers";
@import "layouts/main";
@import "common/general";
@import "ui/index.scss";
@import "modules/index.scss";
@import "landing/index";
@import "../assets/styles/main";

body {
  background: $dark;
  color: #fff;
  overflow-x: auto;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

* {
  font-family: $titleFont, sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
}

body.modal-opened {
  overflow: hidden;
}

.landing-pages {
  font-family: $mainSansSerif;

  form {
    input,
    textarea {
      margin: 20px auto 0;
      padding: 14px 24px;
      width: 100%;
      background: $defaultWhite;
      height: 45px;
      border-radius: 0px;
      color: $titleBlack;
      font-size: 14px;
      line-height: 16px;
      resize: none;
      border: none !important;

      &::placeholder {
        display: inline;
        vertical-align: top;
      }

      &:focus,
      :active {
        outline: none;
        padding: 12px 22px;
        border: 2px solid $borderBlue;
      }
    }
  }
}

.inner-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  padding-left: 220px;

}

.sidebar {
  flex: none;
  width: 200px;
  border-radius: 0;
  background: $darkBox;
  height: calc(100dvh - 20px);
  margin: auto 0;
  position: fixed;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 10px;
  padding-top: 10px;
  overflow-x: hidden;
}

.sidebar-logo {
  margin: auto;
  margin-bottom: 10px;

  img {
    max-width: 100%;
  }
}

.logout-button {
  width: 25px;
  height: 25px;
  border-radius: 0;
  background: $darkInput;
  margin-left: auto;
  flex: none;
  border: none;
  cursor: pointer;
  transition: all .2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;


  img {
    max-width: 100%;
    max-height: 100%;
  }

  &:hover {
    background: $darkButton;
  }
}

.sidebar-navigation {
  padding: 0;
  list-style: none;
  padding-top: 15px;
  width: calc(100% + 20px);
  margin-left: -10px;
  word-break: break-all;

  > li {
    + li {
      border-top: 1px solid rgba(250, 250, 250, .15);
    }

    > a, button {
      font-size: 13px;
      color: #fff;
      display: flex;
      transition: all .2s ease;
      padding: 5px 10px;
      background: none;
      border: none;
      cursor: pointer;
      width: 100%;
      position: relative;
      font-weight: bold;

      &:hover {
        background: rgba(250, 250, 250, .15);
        color: $primaryColor;
      }

      &.active {
        background: rgba(250, 250, 250, .15);

        color: $primaryColor;

      }

      span {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $darkInput;
        border-radius: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.sidebar-sub-menu {
  padding-left: 20px;
  list-style: none;
  background: rgba(250, 250, 250, .05);

  a {
    display: block;
    padding: 3px 0;
    font-size: 12px;
    line-height: 15px;
    color: rgba(250, 250, 250, .75);

    &:hover {
      color: $primaryColor;
    }

    &.active {
      color: $primaryColor;

    }

  }
}

.innner-wrapper-content {
  width: 100%;

  .ant-select-single {
    .ant-select-selector {
      background: $darkInput;
      border: none !important;
    }

    .ant-select-arrow {
      color: #fff;
    }

    .ant-select-selection-item {
      background: transparent;
      color: #fff;
    }
  }

}

.page-navigation {
  &.ant-menu-horizontal {
    border: none;
    border-radius: 0px;
    background: $darkBox;
    width: 100%;
    display: flex;
    justify-content: center;

    .ant-menu-item a {
      color: #fff;
    }

    .ant-menu-item {
      margin: 0 5px !important;
    }
  }
}
.ant-menu-item {
  font-weight: bold;
}
.ant-select-dropdown {
  background: $darkInput;
  color: #fff;
}
.ant-typography {
  color: #fff !important;
}

.ant-card {
  color: #fff;
  background: $darkBox;
  border: none;
}


.ant-checkbox {
  &:after {
    display: none;
  }

  &-inner {
    background: $darkBox;
    width: 24px;
    height: 24px;
    border: none !important;
    outline: none;
    box-shadow: none;

    &::before {
      content: "";
      width: 16px;
      height: 12px;
      background: url("../assets/images/arrow-drop-menu.svg") no-repeat center / contain;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity .2s ease;
    }
  }

  &-checked {
    .ant-checkbox {
      &-inner {
        background: $primaryColor;

        &::after {
          display: none;
        }

        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.ant-select-tree-checkbox {
  &:after {
    display: none;
  }

  &-inner {
    border-color: $primaryColor !important;
  }

  &-checked {
    .ant-select-tree-checkbox {
      &-inner {
        background: $primaryColor;
        border-color: $primaryColor;
      }
    }
  }
}

.ant-switch-checked {
  &::after {
    background-color: $primaryColor;
  }
}

.ant-select-single {
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: 0px;
      display: flex;
      align-items: center;

      &:hover {
        border-color: $primaryColor;

      }

      .ant-select-selection-item {
        background: $primaryColor;
        color: $alwaysWhite;
        border-radius: 0px;
        line-height: 25px;
        margin: 0;
      }
    }

    .ant-select-selection-placeholder {
      padding-top: 0;
    }
  }

  &.ant-select-focused {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-color: $primaryColor !important;
        box-shadow: none !important;
      }
    }
  }
}
.ant-select-item {
  color: #fff;
  &:hover {
    background: transparent;
    color: $primaryColor;
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: transparent;
  color: $primaryColor;
  border-radius: 5px;
}


.radio-select-dropdown {

  > div {
    background: $darkBox;

  }

  .ant-select-item-option-selected .radio-select-option-inner {
    border-color:transparent;

    &:after {
      background:transparent;
    }
  }

  &::before {
    border-bottom-color: $darkBox;
  }

}

.ant-popover {
  .ant-popover-arrow {
    border-color: $darkBox !important;
  }

  .ant-popover-inner {
    background: $darkBox;
  }
}

.ant-spin-dot-item {
  background-color: $primaryColor;
}

.ant-input-affix-wrapper,
.ant-cascader-input.ant-input {
  border-radius: 0px;

  &:hover {
    border-color: $primaryColor;
  }
}

.ant-cascader-picker {
  &:hover {
    .ant-cascader-input {
      border-color: $primaryColor !important;
    }
  }

  &:focus {
    .ant-cascader-input {
      border-color: $primaryColor !important;
      box-shadow: 0 0 7px -3px $primaryColor;
    }
  }
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: $primaryColor;
  color: $alwaysWhite;

  path {
    fill: $alwaysWhite;
  }
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 5px;
  background-color: $primaryColor;
  color: $alwaysWhite;

  path {
    fill: $alwaysWhite;
  }
}

.ant-typography,
.ant-card-head-title,
.ant-form-item-label,
.ant-table-thead {
  font-family: $fontPrimary;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: $primaryColor !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: $primaryColor !important;
  border-color: rgba($primaryColor, .5) !important;

}

.ant-picker-cell-in-view.ant-picker-cell-today {
  font-family: $fontPrimary;
  color: #fff;
  opacity: 1;

  .ant-picker-cell-inner {
    background: $primaryColor !important;

  }

  .ant-picker-cell-inner::before {
    border: 1px solid transparent;
    display: none;
  }
}

.ant-picker-cell-in-view .ant-picker-cell-inner::after,
.ant-picker-cell-in-view .ant-picker-cell-inner::before {
  background: rgba($primaryColor, .5) !important;
  border-color: rgba($primaryColor, .5) !important;

}

.ant-picker-cell-in-view {
  &::before,
  &::after {
    border-color: rgba($primaryColor, .5) !important;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-time-panel-column
> li.ant-picker-time-panel-cell-selected
.ant-picker-time-panel-cell-inner {
  background: rgba($primaryColor, .5) !important;
}

.ant-picker {
  background: $darkInput;
  border: none !important;

  input {
    color: #fff !important;
  }

  .ant-picker-range-separator {
    color: #fff;
  }
}

.ant-picker-panel-container {
  background: $darkInput !important;
  color: #fff !important;
}

.ant-picker-panel {
  border: none !important;
  background: $darkInput !important;
  color: #fff !important;
}

.ant-picker-header {
  border-bottom: 1px solid rgba(250, 250, 250, .15);

  button {
    color: #fff !important;
  }
}
.ant-picker-content th {
  color: #fff;
}

.ant-picker-cell{
  color: #fff;
  &:hover {
    .ant-picker-cell-inner {
      background: rgba(250, 250, 250, .15) !important;
    }
  }
}
.ant-picker-range-arrow::after {
  border: 5px solid $darkInput;
  border-color: $darkInput $darkInput transparent transparent;
}

.ant-input-affix-wrapper {
  border-radius: 0px;
  background: $darkInput !important;
  border: none;

}
.ant-input {
  background: $darkInput !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  color: #fff !important;
}
.ant-table {
  color: #fff;
  background: transparent;
}
.ant-table-thead > tr > th {
  color: #fff;
  font-weight: bold;
  background: transparent;
  border-bottom: 1px solid rgba(250, 250, 250, .15);
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid  rgba(250, 250, 250, .15);
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: rgba(250, 250, 250, .15);
}

.ant-pagination-item-link {
  background: $darkInput !important;
  color: #fff !important;
  border: none !important;
  display: block;

  &:hover {
    color: $primaryColor !important;
    .anticon {
      color: $primaryColor;
    }
  }
}

.ant-pagination-item {
  background: $darkInput;
  color: #fff;
  border: none;
  a {
    color: #fff;
    transition: all .2s ease;
    &:hover {
      color: $primaryColor;
    }
  }
}

a {
  color: $primaryColor;
  &:hover {
    color: $primaryColor;
  }
}
.ant-collapse,
.filters-main {
  background: $darkBox;
}

input {
  color: #fff;
}
.ant-modal-content,
.ant-modal-header {
  background: $darkBox;
  color: #fff;
}
.ant-modal-header {
  border-bottom: 1px solid rgba(250, 250, 250, .15);
  .ant-modal-title {
    color: #fff;
  }

}
.ant-modal {
  .ant-modal-close-x {
    color: #fff;
  }
}

.ant-steps {
  background: $darkBox;
}
.ant-steps-item + .ant-steps-item {
  border-left: 1px solid rgba(250, 250, 250, .15);
}
.ant-steps-item-title {
  color: #fff !important;
}
.ant-card-head {
  color: #fff;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #fff;
}
.ant-collapse-content {
  background: $darkBox;
  color: #fff;
}
.ant-empty {
  background: $darkBox;
  color: #fff;
}
.ant-collapse-header,
.ant-list-item-meta-title,
.ant-list-item-meta-description {
  color: #fff;
}

.ant-list-item {
  background: $darkBox;
  color: #fff;
}
.ant-table-summary {
  .ant-table-cell {
    background: rgba(250, 250, 250, .3);
  }
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: rgba(250, 250, 250, .15);
}
.ant-table-row-expand-icon {
  color: #fff !important;
  background: $darkBox !important;
  border-color: $darkBox !important;
}
.ant-table tr.ant-table-expanded-row td {
  background: rgba(250, 250, 250, .3);
}
.ant-table tr.ant-table-expanded-row th {
  background: $darkBox;
}
.ant-table {
  margin: 0 !important;
}
.ant-checkbox-wrapper {
  color: #fff;
}
.ant-checkbox-inner {
  background: $darkInput;
}
.ant-tabs {
  color: #fff;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: transparent;
}
.ant-form {
  color: #fff;
}
.ant-table-small .ant-table-thead > tr > th {
  background: $darkInput !important;
}
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
  background: transparent;
}
.ant-select-selector {
  background: $darkInput !important;
  border: none !important;
  color: #fff !important;

}
.ant-select-arrow {
  .anticon {
    color: #fff !important;
    path {
      fill:#fff !important;
    }
  }
}

.ant-select-tree {
  background: transparent;
  color: #fff;
}
.ant-select-tree-checkbox-inner {
  background: $darkBox;
  border: 1px solid rgba(250, 250, 250, .15) !important;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-table-tbody > tr > td {
  background: $darkInput;
}
.ant-table-tbody > tr {
  td {
    font-size: 12px;
  }
  &:hover {
    >td {
      background: rgba(250, 250, 250, .15) !important;
    }
  }
}
.ant-picker-cell-disabled::before {
  background: rgba(250, 250, 250, .15) !important;
}
.ant-cascader-picker {
  color: #fff;
  background: $darkInput;

  .anticon {
    color: #fff;

  }
}
.ant-cascader-menus {
  background: $darkInput;
  box-shadow: none;
  color: #fff;
  .anticon {
    color: #fff;
  }

  .ant-cascader-menu-item {
    &:hover {
      background: rgba(250, 250, 250, .15) !important;
    }
  }
}

.ant-list-split .ant-list-item {
  border-bottom: 1px solid rgba(250, 250, 250, .15);
}

h1, h2, h3, h4, h5, h6 {
  color: #fff;
}
.ant-input-password-icon {
  margin-right: 5px;
  color: #fff;
}
.ant-tag {
  border: none;
  background: rgba(250, 250, 250, .3);
  color: rgba(250, 250, 250, .75);
  &-green {
    color: #fff;
    background: #327710;
    border-color: #b7eb8f;
  }
  &-blue {
    color: #fff;
    background: #0a3980;
    border-color: #b7eb8f;
  }
}
.ant-table-filter-dropdown {
  background: $darkBox;
}
.ant-switch-handle::before {
  border-radius: 0;
}
.ant-radio-wrapper {
  color: #fff;
}