.datepicker {
  @include input();

  border: 1px solid #d9d9d9;
  padding: 0 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $white_bg;

  &-dropdown {
    .ant-picker-panel-container {
      background: $dart_blue;
      margin-top: 20px;
      border-radius: 8px;

      &::before {
        @include arrow($dart_blue);
        position: absolute;
        top: 10px;
        left: 30px;
      }
    }

    .ant-picker {
      &-header {
        color: $alwaysWhite;
        border: none;

        button {
          color: $alwaysWhite;
        }

        &-super-next-btn,
        &-super-prev-btn {
          display: none;
        }
      }

      &-content {
        th {
          color: $alwaysWhite;
        }
      }

      &-cell {
        color: $popup_text;
        opacity: 0.5;

        &-inner {
          min-width: 26px;
          height: 26px;
          line-height: 26px;
        }

        &-in-view {
          color: $stroke;
          opacity: 1;

          .ant-picker-cell-inner {
            border-radius: 0px;
          }

          &.ant-picker-cell-selected .ant-picker-cell-inner {
            font-weight: bold;
          }

          &.ant-picker-cell-today {
            font-weight: bold;

            .ant-picker-cell-inner::before {
              border-color: $stroke;
              border-radius: 0px;
            }
          }
        }
      }
    }
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
      .ant-picker-cell-inner {
      background: $greyMain;
    }
  }

  .ant-picker {
    border: none;
    padding: 0px;
    padding-left: 4px;

    &:hover,
    &-focused {
      border: none;
      box-shadow: none;
    }

    &-focused {
      background: $tertiaryMain;

      .ant-picker-input {
        & > input {
          color: $secondaryMain;
        }
      }
    }
  }
}
