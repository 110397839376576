.inline-info {
  font-size: 12px;
  display: inline-block;

  &__label {
    margin-right: 5px;
    color: $greyMain;
  }

  &__data {
    color: #fff;

    path {
      fill: $primaryColor;
    }
  }
}