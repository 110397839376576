.nav-cards {
  margin-top: 50px;

  @include media("<568px") {
    margin-top: 30px;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding: 0;
    list-style: none;
  }
  &__list-item {
    width: calc(100% / 3);
    padding: 0 6px;
    padding-bottom: 12px;

    @include media("<=860px") {
      width: 50%;
    }
    @include media("<568px") {
      width: 100%;
    }
  }
}

.nav-card {
  height: 110px;
  display: flex;
  align-items: center;
  padding: 0;
  background: $darkBox;
  border-radius: 25px;
  color: #fff;
  padding-left: 40px;
  transition: all .2s ease;

  &:hover {
    color: $primaryActiveColor;
  }
  &__title {
    font-size: 18px;
    font-weight: 700;
  }
  &__image {
    margin: 0 auto;
    width: 100px;
    height: 85px;
    background: coral;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;

    @include media('<568px') {
      margin: 0;
      margin-left: auto;
      margin-right: 15px;
    }
  }

  &--1 {
    .nav-card {
      &__image {
        animation: morphing 13s infinite ease alternate both;
        background: rgb(168,71,0);
        background: linear-gradient(0deg, rgba(168,71,0,1) 0%, rgba(29,29,43,1) 100%);
      }
    }
  }
  &--2 {
    .nav-card {
      &__image {
        animation: morphing 10s infinite linear alternate both;
        background: rgb(255,166,32);
        background: linear-gradient(0deg, rgba(255,166,32,1) 0%, rgba(29,29,43,1) 100%);
      }
    }
  }
  &--3 {
    .nav-card {
      &__image {
        animation: morphing 15s infinite ease-in-out alternate both;
        background: rgb(255,110,101);
        background: linear-gradient(0deg, rgba(255,110,101,1) 31%, rgba(29,29,43,1) 94%);
      }
    }
  }
}

@keyframes morphing {
  0%, 100% {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
  25% {
    border-radius: 58% 42% 75% 25% / 76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67% / 55% 27% 73% 45%;
  }
  75% {
    border-radius: 33% 67% 58% 42% / 63% 68% 32% 37%;
  }
}