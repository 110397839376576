
.benefits-grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  padding: 0;
  list-style: none;
  padding-top: 50px;

  &__item {
    width: calc(100% / 3);
    padding: 0 12px;
    padding-bottom: 24px;

    @include media('<=860px') {
      width: 100%;
      +* {
        margin-top: 50px;
      }
    }

  }
}

.benefit-card {
  background-color: $darkBox;
  border-radius: 10px;
  position: relative;
  color: #fff;
  font-size: 16px;
  line-height: 1.3;
  font-weight: bold;
  padding: 20px;


  @include media("<568px") {
    font-size: 14px;
  }
  &__title {
    max-width: 175px;
    text-align: center;
    margin: 0 auto;
  }
  &__icon {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 150px;
    margin-bottom: 10px;
    margin-top: -80px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}