

.news-list {
  .ant-card-bordered {
    border: none;
  }
  .ant-card-meta-title {
    color: $white;
  }
  .ant-card-meta-description {
    color: $white;
    font-size: 12px;
  }

  &__post {
    width: auto;
    margin-bottom: 30px;
    border-radius: 15px;
    margin-left: 0;
    margin-right: 0;
    background-color: $darkBox;

    .ant-card-cover {
      justify-content: center;
      display: flex;
    }
    .ant-card-body {
      padding: 0;
    }

    &-img {
      width: 95%;
      margin-top: 25px;
      border-radius: 8px;
    }

    &-content {
      width: 95%;
    }

    &-content-text {
      margin-top: 20px;
      font-weight: 400;
      color: $white;
      font-size: 17px;
    }
    &-date {
      font-size: 12px;
      font-style: italic;
      opacity: .75;
    }

    .ant-card-body {
      display: flex;
      justify-content: center;
    }
  }
}
.ant-card-meta-title {
  color: #fff;
}
.ant-card-meta-description {
  color: #fff;
}
@include tablet {
  .news-list {
    &__post {
      margin-left: 50px;
      margin-right: 50px;
    }
  }
}
@include large-mobile {
  .news-list {
    &__post {
      .ant-card-body {
        padding: 24px 0;
      }
    }
  }
}
