.btn {
  @include commonBtnStyles;
  @include btn-primary;

  &--border {
    @include btn-border;
  }
  &--second {
    @include btn-secondary;
  }
  &--black {
    background: #1D1D2A;
    color: #fff;
    &:hover {
      color: $primaryActiveColor;
      background-color: lighten(#1D1D2A, 5%);
    }
  }
}

.ant {
  &-btn {
    color: #fff;
    background: $darkInput;
    border: none;
    line-height: 8px;
    border-radius: 0 !important;
    font-weight: bold;

    path {
      //fill: #fff;
    }
    &:hover,
    &:focus{
      color: #fff;
      background: $primaryColor;
    }
    &-circle {
      border-radius: 0 !important;
    }

    &-lg {
      height: 45px;
    }

    &-circle.ant-btn-lg {
      height: 45px;
      width: 45px;
    }

    &-round {
      display: inline-flex;
      border-radius: 0;
      align-items: center;

      &.ant-btn-lg {
        border-radius: 0;
        font-size: 12px;
        padding: 10px 40px;
        height: 45px;
      }
    }

    &-primary {
      @include btn-primary;
      border: none !important;
    }

    &-secondary {
      @include btn-secondary;
    }

    &-tertiary {

      @include btn-tertiary;
    }

    &-default {
      @include btn-default;
    }

    &-with-icon {
      @include with-icon;
    }

    &-loading {
      @include loading;
    }
  }
}
